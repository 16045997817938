import React from 'react'
import { navigate } from '@reach/router'

export default function NahFam(){
  return(
    <section id="nah">
      <h1>Nah Fam...</h1>
      <h3>Honey, you can't reach that page.</h3>
      <a onClick={() => navigate("/")} id="enter">log in</a>
    </section>
  )
}
