import firebase from 'firebase'
import {firebaseConfig} from './newfire'

export default class Fire{
  constructor(){
    this.init()
    this.observerauth()
  }

  init = () => {
    if (!firebase.apps.length) {
			firebase.initializeApp(firebaseConfig);
		}
  }

  observerauth = () => {
		//calls auth situations
		firebase.auth().onAuthStateChanged(this.onAuthStateChanged2);
  };

  onAuthStateChanged2 = (user) => {
		if (!user) {
			try {
				console.log('Maybe navigate to correct screen?');
			} catch ({ message }) {
				//if something goes wrong basically
				alert(message);
			}
		} else {
			console.log('there is a user!!');
		}
  };

  getUID() {
		let me = (firebase.auth().currentUser || {}).uid;
		return me;
  }

  login = async (email, pass) => {
		return firebase.auth().signInWithEmailAndPassword(email, pass);
  };

	logout = () => {
		return firebase.auth().signOut();
  };

}

Fire.shared = new Fire();
