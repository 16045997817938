import React, {useState} from 'react'
import Fire from "../Fire"
import {
  Link,
  navigate
} from "@reach/router"

export default function Enter(){
  const [email, setEmail] = useState("")
  const [pass, setPass] = useState("")
  const [loginError, setLoginError] = useState("none")
  const puffIn = async () => {
    const trueEmail = `${email}@blackpowerpuffs.com`
    console.log(trueEmail, pass)
    await Fire.shared.login(trueEmail, pass)
    .then(()=>{
      //navigate to next page
      navigate("/invite")
    }).catch(async (e)=> {
      console.log(e.message)
      await setLoginError("block")
    })
  }
  return(
    <section id="passPage-section">
      <h1>black power puffs</h1>
      <form action="./makboard.html"
        onSubmit={async (event) => {
          event.preventDefault()
          return await puffIn()
        }}
      >
        <input type="text" placeholder="username" required
          name={'setEmail'}
          onChange={(evt) => {
            return setEmail(evt.target.value)}}
        />
        <input type="password" placeholder="password" required
          name={'setPass'}
          onChange={(evt) => {
            return setPass(evt.target.value)}}
        />
        <button type="submit">enter</button>
      </form>
      <p style={{color: 'red', display: loginError}}>Unfortunately, that username or password was incorrect. Try again.</p>
    </section>
  )
}
