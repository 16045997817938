import firebase from 'firebase'

export const firebaseConfig = {
  apiKey: "AIzaSyBfjNX5cI248VTB7HGRK-pvg6aExsrWpiE",
  authDomain: "blackpowerpuffs-45717.firebaseapp.com",
  databaseURL: "https://blackpowerpuffs-45717.firebaseio.com",
  projectId: "blackpowerpuffs-45717",
  storageBucket: "blackpowerpuffs-45717.appspot.com",
  messagingSenderId: "19147228658",
  appId: "1:19147228658:web:99770c5922058d181b576b",
  measurementId: "G-NDC900JGML"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
