import React from 'react'
import { useLoading, ThreeDots } from '@agney/react-loading';

export default function Loading(){
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    // loaderProps: {
    //   // Any props here would be spread on to the indicator element.
    //   style: {{ margin: '0 auto'}}
    // },
    indicator: <ThreeDots width="30%" fill="#fff"/>,
  });
  return(
    <section id="loading">
      <h1>loading</h1>
      <section {...containerProps} id="myLoad">
        {indicatorEl} {/* renders only while loading */}
      </section>
    </section>

  )
}
