import React, { useState, useEffect } from 'react'
import Fire from '../Fire'
import firebase from "../newfire"
import {useDocument} from 'react-firebase-hooks/firestore';
import userEvent from '@testing-library/user-event';
import { navigate } from '@reach/router';
import Loading from './Loading'
import "../holiday2020Styles.css"
import pyjamas from '../assets/svg/pyjamas.svg'
import marshmallow from '../assets/svg/marshmallow.svg'
import money from '../assets/svg/money.svg'
import group from '../assets/svg/group.svg'
import laptop from '../assets/svg/laptop.svg'
export default function Holiday2020() {
  const [value, loading, error] = useDocument(
    firebase.firestore().collection('bpp').doc(`${Fire.shared.getUID()}`)
  );

  const [rsvp, setRsvp] = useState(false)
  const [guest, setGuest] = useState(false)

  useEffect(()=>{
    //initialize the rsvp to be what is on the user doc.
    if(value && value.data()){
      setRsvp(value.data().rsvp)
    }

    if(value && value.data() && value.data().name === 'guest'){
      setGuest(true)
    }
  })

  const puffOut = () =>{
    Fire.shared.logout().then(()=>{
      navigate("/comeback")
    }).catch((e)=> console.log(e.message))
  }

  const rsvpFunc = async () => {
    if(!value.data().rsvp){
      await value.ref.update({rsvp: true})
      setRsvp(true)
    }
  }

  if(error){
    return <div><h1>{error}</h1></div>
  }

  if(loading){
    return <Loading/>
  }

  if(value && value.data()){
    return(
      <section id="holiday2020section">
      {/* If the user has already rsvped or upon comfirming their rsvp, show banner. */}
      {value.data().rsvp ?
      (
        <div id="rsvped">
          <h1>You&apos;re rsvped!</h1>
        </div>
      ): null}

      <div id="holiday2020">
        <div id='details'>
          <h1>
            Holiday Bash 2020
            <a onClick={puffOut}id="exit2">log off</a>
          </h1>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum aliquid eum fuga eos eius officiis vel veniam! Suscipit dolorum perspiciatis quod vitae recusandae veritatis numquam sed rerum labore deserunt? Id laboriosam voluptatem quaerat tenetur eum sapiente quo saepe magnam amet animi earum, odit nesciunt cum hic ratione. Tempora, dolores error?</p> */}
          <p>Welcome, welcome, welcome! It's time to get a party started for the end of this weird, stressful, scary yet comfortable and hilarious year. Best way to do it? With friends and the magic of virtual awesomeness. So get ready to laugh harder, conspire, and enjoy each other's company. Just don't forget to bring your personality and everything else in the following list below. </p>
          <div id="deets">
            <h3>When: <span>Saturday December 26th, 2020</span></h3>
            <h3>Where: <span>TBD</span></h3>
            {
              guest ? null : (
                value.data().rsvp ?
                  (<a id="rsvp" href="#">RSVP &#10003;</a>)
                  :(<a onClick={rsvpFunc} id="rsvp" href="#">RSVP ?</a>)
              )
            }
            {/* <a id="calendar" target="_blank" href="https://calendar.google.com/event?action=TEMPLATE&tmeid=NjB2MWQ5MTFhczdwMGxwNTBkbm5xODV0Mjkga2ptYW5zb24xNUBt&tmsrc=kjmanson15%40gmail.com">Add To Calendar</a> */}
            {/* Invite to the whole calendar. */}
            {guest ? null : (<a id="calendar" target="_blank" href="https://calendar.google.com/calendar/u/0?cid=ZHM1ZmJnM2ptbWxsa3U0OTduYjN2dWY2ZnNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ">Add To Calendar</a>)}
            {/* Invite to the event, but weirdly gives guests edit access or a 404 if they aren't logged in. */}
            {/* <a id="calendar" target="_blank" href="https://calendar.google.com/event?action=TEMPLATE&tmeid=NWEzbDQxaGhydmVtYWU1cmoyNTdpN3Z0bGggZHM1ZmJnM2ptbWxsa3U0OTduYjN2dWY2ZnNAZw&tmsrc=ds5fbg3jmmllku497nb3vuf6fs%40group.calendar.google.com">Add To Calendar</a> */}
          </div>
        </div>

        <div id="list">
          <ul>
            <li>
              <i class="material-icons">check_circle</i>
              <img src={pyjamas}/>
              <h2>Pajamas and Socks</h2>
            </li>
            <p>If you don't have on fancy socks, your ass will get kicked out. With love.</p>
            <li>
              <i class="material-icons">check_circle</i>
              <img src={marshmallow}/>
              <h2>Hot Chocolate</h2>
            </li>
            <p>It's gonna get all cozy and shit. So you better be drinking the finest.</p>
            <li>
              <i class="material-icons">check_circle</i>
              <img src={money}/>
              <h2>Money</h2>
            </li>
            <p>$20. A prime experience is rarely free. But if you need to be spotted, hit me up.</p>
            <li>
              <i class="material-icons">check_circle</i>
              <img src={group}/>
              <h2>Activity</h2>
            </li>
            <p>Come up with something cool to do. This can be an exercise, small game, etc. Keep it cheery!</p>
            <li>
              <i class="material-icons">check_circle</i>
              <img src={laptop}/>
              <h2>Technology</h2>
            </li>
            <p>You will need a fully charged phone and laptop/console. Preferably a laptop ready for gaming. #Virtual-party-basics.</p>
          </ul>
        </div>
        <a onClick={puffOut} id="exit">log off</a>
      </div>

      <div id="assetMention">
        <div>Icons made by <a href="https://www.flaticon.com/authors/photo3idea-studio" title="photo3idea_studio">photo3idea_studio</a> and <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
      </div>
    </section>
    )
  }
}
