import { navigate } from '@reach/router'
import React from 'react'

export default function ComeBack(){
  return(
    <section id="comeback-section">
      <h1>come back soon</h1>
      <a onClick={() => navigate("/")} id="enter">log in</a>
    </section>
  )
}
