import React from 'react';
import logo from './logo.svg';
// import './App.css';
import "./oct2020styles.css"
import {Route, Switch} from 'react-router-dom'
import {Router, Redirect} from '@reach/router'
import firebase from "./newfire"
import { useAuthState } from 'react-firebase-hooks/auth';
import Enter from "./pages/Enter"
import Comeback from "./pages/Comeback"
import InviteBoard from "./pages/InviteBoard"
import NahFam from "./pages/NahFam"
import Loading from './pages/Loading';
import Holiday2020 from './pages/Holiday2020'
// import "./holiday2020Styles.css"

function App() {
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>

    const [ user, loading, error ] = useAuthState(firebase.auth());

    // if(error){
    //   return(
    //     <div> Error: {error} </div>
    //   )
    // }

    // if(loading){
    //   return(
    //     <h1>Loading!</h1>
    //   )
    // }

    // if (user){
    //   //user signed in
    //   return(
    //     <Route exact path="/invite" component={InviteBoard}/>
    //   )
    // } else {
    //   //user not signed in
    //   console.log("uer not signed in!")
    //   return(
    //     <Router>
    //       <Route path="hello" component={Enter}/>
    //     </Router>
    //     // <Switch>
    //     //   <Route exact path="hello" component={Enter}/>
    //     //   <Route exact path="bye" component={ComeBack}/>
    //     // </Switch>
    //   )
    // }
    return(
       error ? (<div> Error: {error} </div>)
       : loading ? (<Loading/>)
       : user ? (
          <Router>
            <Redirect from="*" to="invite" noThrow />
            {/* <InviteBoard path="/invite"/> */}
            <Holiday2020 path="/invite"/>
            {/* <NahFam path="*"/> */}
            {/* <Route exact path="/invite" component={InviteBoard}/> */}
          </Router>
        ): (
          <Router>
            <Enter path="/"/>
            <Comeback path="comeback"/>
            <NahFam path="*"/>
              {/* <Route exact path="hello" component={Enter}/> */}
          </Router>
        )
    )
}

export default App;
